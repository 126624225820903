<template>

  <div>

    <div class="mt-1 d-flex w-100 justify-content-between">
      <b-button
          variant="link"
          class="p-0"
          :disabled="isParentBusy"
          @click="handleOpenModal"
      >Registrar credenciales...</b-button>
    </div>

    <b-modal
        v-model="showModal"
        centered
        title="Registro de Credenciales"
        no-close-on-backdrop
        @hidden="handleCloseModal"
    >
      <b-form-group
          label="Username"
          label-for="user-name"
      >
        <b-form-input id="user-name" v-model="credentials.frsUsername"/>
      </b-form-group>
      <b-form-group
          label="Password"
          label-for="password"
      >
        <b-form-input id="password" v-model="credentials.frsPassword"/>
      </b-form-group>
      <b-form-group
          label="Link"
          label-for="link"
      >
        <b-form-input id="link" v-model="credentials.frsLink"/>
      </b-form-group>

      <template #modal-footer>
        <div class="w-100">

          <b-button
              variant="flat-danger"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              v-if="hasCredentials"
              :disabled="isBusy"
              @click="handleResetCredentials"
          >
            Quitar credenciales
          </b-button>

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="float-right"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="handleSaveCredentials"
              :disabled="hasSomeNullEmptyItem || isBusy"
          >
            <b-spinner small v-if="isBusy"/>
            Guardar
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              class=" mr-1 float-right"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="handleCancel"
              :disabled="isBusy"
          >
            Cancelar
          </b-button>
        </div>
      </template>

      <validation-errors v-if="errors" :errors="errors"/>

    </b-modal>

  </div>

</template>


<script>
import { BButton, BModal, BFormGroup, BFormInput, BFormCheckbox, BSpinner } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ValidationErrors from '@/modules/components/ValidationErrors.vue'

export default {
  name: 'TareaToDoItem',
  props: {
    task: {
      type: Object,
      required: true,
      default: {}
    },
    isParentBusy: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  components:{
    BSpinner,
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    ValidationErrors,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data(){
    return {
      errors: [],
      showModal: false,
      isBusy: false,
      credentials: {
        frsId: null,
        frsUsername: null,
        frsPassword: null,
        frsLink: null
      }
    }
  },
  computed: {
    // verificar si algun elemento de un objeto es NULL o vacío
    hasSomeNullEmptyItem () {
      const nullable = ( element ) => element === null || element === ''
      return Object.values( this.credentials ).some( nullable )
    },
    //determinar si existen credenciales guardadas
    hasCredentials () {
      return this.credentials.frsUsername !== null
    }

  },

  methods: {
    ...mapActions('tasks-module', ['updateCredentials', 'resetCredentials']),
    ...mapActions('solicitante-solicitudes-module', ['fetchFormSystemById']),

    //2023-06-06 | fg | cargar info del registro
    async loadData(){

      try {

        const data  = await this.fetchFormSystemById( this.task.frsId )

        this.credentials.frsId = data.frsId
        this.credentials.frsUsername = data.frsUsername
        this.credentials.frsPassword = data.frsPassword
        this.credentials.frsLink = data.frsLink

      } catch (error) {

      } finally {

      }

    },

    //////////////////
    async handleOpenModal(){
      await this.loadData()
      this.showModal = true
    },
    // reset los campos del formulario
    resetForm(){
      Object.keys(this.credentials).forEach( key =>  this.credentials[key] = null )
    },
    handleCancel(){
      this.resetForm()
      this.showModal = false
    },
    handleCloseModal() {
      this.resetForm()
    },
    /////////////////////////



    // procesar peticion de guardar
    async handleSaveCredentials(){
      try {
        this.errors = []
        this.isBusy = true
        await this.updateCredentials( this.credentials )
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Credenciales',
            icon: 'CheckIcon',
            text: '¡Credenciales registradas exitosamente!',
            variant: 'success'
          },
        })
        this.showModal = false
      } catch (error) {
        if ( error.response.status == 422 ) {
          const  data  = error.response.data
          // errores de validacion
          this.errors = data
        }
      } finally {
        this.isBusy = false
      }
    },

    // procesar peticion de reset credentials
    async handleResetCredentials(){
      try {
        this.isBusy = true
        await this.resetCredentials( { frsId: this.credentials.frsId } )
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Credenciales',
            icon: 'InfoIcon',
            text: 'Credenciales fueron restablecidas',
            variant: 'info'
          },
        })
        this.showModal = false
      } catch (error) {

      } finally {
        this.isBusy = false
      }
    }
  },


}

</script>


<style scoped lang="scss">

</style>
